import { tiendasApi } from '../api'

export const abonadosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPaginatedAbonados: builder.mutation({
            query: ({ body, extraParams }) => ({
                url: 'abonados/get-paginated/' + extraParams.filtro,
                method: 'POST',
                body: body,
            })
        }),
        getByIdAbonado: builder.mutation({
            query: (idAbonado) => ({
                url: 'abonados/' + idAbonado,
                method: 'GET',
            })
        }),
        toggleActivado: builder.mutation({
            query: (idAbonado) => ({
                url: 'abonados/' + idAbonado + '/toggle-activo',
                method: 'PUT',
            })
        }),
        removeAbonado: builder.mutation({
            query: (idAbonado) => ({
                url: 'abonados/' + idAbonado,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPaginatedAbonadosMutation,
    useGetByIdAbonadoMutation,
    useToggleActivadoMutation,
    useRemoveAbonadoMutation,
} = abonadosApiSlice