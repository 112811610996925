import { React, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Moment from 'react-moment'

import { useGetByIdAbonadoMutation } from '../../../store/api/endpoints/abonadosApiSlice'

import { openFeedback } from '../../../store/slices/feedback'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'

import ToggleActivado from './ToggleActivado'

import { Breadcrumbs, Button, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import SearchIcon from '@mui/icons-material/Search'
import GroupIcon from '@mui/icons-material/Group'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteIcon from '@mui/icons-material/Delete'


export default function Abonado() {

    const urlParams = useParams()
    const dispatch = useDispatch()

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetByIdAbonadoMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Abonados</Typography>
                        <Typography color="text.primary">{responseGetRegistro.nombre}</Typography>
                    </Breadcrumbs>
                }
                icon={<GroupIcon />}
                end={
                    <Link to={"/abonados/eliminar/" + urlParams.id}>
                        <Button type="button" variant="contained" color="error" startIcon={<DeleteIcon />} >
                            Eliminar
                        </Button>
                    </Link>
                }
            />
            <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid size={{ xs: 12, md: 4 }} sx={{ textAlign: 'left' }}>
                    <p><b>Activo:</b>
                        {responseGetRegistro.activo && <> <CircleIcon style={{ fontSize: '0.9rem', color: 'green' }} /> Sí</>}
                        {!responseGetRegistro.activo && <> <CircleIcon style={{ fontSize: '0.9rem', color: 'red' }} /> No</>}
                    </p>
                    <p><b>Completó el Formulario:</b> <Moment date={responseGetRegistro.date_completa_form} format='DD-MM-YYYY HH:mm:ss' /></p>
                    <p><b>Ingresó Tarjeta:</b> -</p>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} sx={{ textAlign: 'left' }}>
                    <p><b>DNI:</b> {responseGetRegistro.dni}</p>
                    <p><b>Nombre:</b> {responseGetRegistro.nombre}</p>
                    <p><b>Email:</b> {responseGetRegistro.email}</p>
                    <p><b>Teléfono:</b> {responseGetRegistro.telefono}</p>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} sx={{ textAlign: 'left' }}>
                    <p><b>CUIT:</b> {responseGetRegistro.cuit}</p>
                    <p><b>Empresa:</b> {responseGetRegistro.empresa}</p>
                    <p><b>Cond. AFIP:</b> {responseGetRegistro.condicion_afip}</p>
                    <p><b>Comerciante:</b> {responseGetRegistro.comerciante}</p>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} sx={{ textAlign: 'left' }}>
                    <p><b>¿Cómo Llegaste?:</b> {responseGetRegistro.como_llegaste}</p>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Divider />
                </Grid>
                <Grid size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
                    <ToggleActivado
                        id={urlParams.id}
                        activado={responseGetRegistro.activo}
                        getRegistro={getRegistro}
                    />
                </Grid>
            </Grid>
        </>
    )

}