import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './assets/css/Routes.css'

//Componentes Vistas
import Layout from './components/vistas/auth/Layout'
import Public from './components/vistas/auth/Public'
import RequireAuth from './components/vistas/auth/RequireAuth'
import Login from './components/vistas/auth/Login'

import Navbar from './components/vistas/Navbar'

import Home from './components/vistas/Home'

import Cronograma from './components/vistas/cronograma/Cronograma'
import CronogramaCrear from './components/vistas/cronograma/CronogramaCrear'
import CronogramaEditar from './components/vistas/cronograma/CronogramaEditar'
import CronogramaEliminar from './components/vistas/cronograma/CronogramaEliminar'

import Descuentos from './components/vistas/descuentos/Descuentos'
import DescuentosCrear from './components/vistas/descuentos/DescuentosCrear'
import DescuentosEditar from './components/vistas/descuentos/DescuentosEditar'
import DescuentoEliminar from './components/vistas/descuentos/DescuentoEliminar'

import Productos from './components/vistas/productos/Productos'
import ProductosCrear from './components/vistas/productos/ProductosCrear'
import ProductosEditar from './components/vistas/productos/ProductosEditar'
import ProductosEliminar from './components/vistas/productos/ProductosEliminar'

import Abonados from './components/vistas/abonados/Abonados'
import Abonado from './components/vistas/abonados/Abonado'
import AbonadoEliminar from './components/vistas/abonados/AbonadoEliminar'

export default function Router() {



  return (
    <BrowserRouter>
      <Navbar />

      <Routes >

        <Route path="/" element={<Layout />} />
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />

        <Route element={<RequireAuth />}>

        <Route path="home" element={<Home />} />

        <Route path="cronograma" element={<Cronograma />} />
        <Route path="cronograma/crear" element={<CronogramaCrear />} />
        <Route path="cronograma/editar/:idCronograma" element={<CronogramaEditar />} />
        <Route path="cronograma/eliminar/:idCronograma" element={<CronogramaEliminar />} />

        <Route path="descuentos" element={<Descuentos />} />
        <Route path="descuentos/crear" element={<DescuentosCrear />} />
        <Route path="descuentos/editar/:idDescuento" element={<DescuentosEditar />} />
        <Route path="descuentos/eliminar/:idDescuento" element={<DescuentoEliminar />} />

        <Route path="productos" element={<Productos />} />
        <Route path="productos/crear" element={<ProductosCrear />} />
        <Route path="productos/editar/:idProducto" element={<ProductosEditar />} />
        <Route path="productos/eliminar/:idProducto" element={<ProductosEliminar />} />

        <Route path="abonados/:filtro" element={<Abonados />} />
        <Route path="abonados/ficha/:id" element={<Abonado />} />
        <Route path="abonados/eliminar/:id" element={<AbonadoEliminar />} />

        </Route>

      </Routes>



    </BrowserRouter >
  );

}