import { React, useEffect } from 'react'

import { useToggleActivadoMutation } from '../../../store/api/endpoints/abonadosApiSlice'

import { openFeedback } from '../../../store/slices/feedback'


import { Button, Divider } from '@mui/material'
import { useDispatch } from 'react-redux'
import Loading from '../../elementos/Loading'



export default function ToggleActivado({ id, activado, getRegistro }) {

    const dispatch = useDispatch()

    const [
        toggleActivado,
        {
            data: responsetoggleActivado,
            isUninitialized: isUninitializedtoggleActivado,
            isLoading: isLoadingtoggleActivado,
            isSuccess: isSuccesstoggleActivado,
            isError: isErrortoggleActivado,
            error: errorDatatoggleActivado,
        }
    ] = useToggleActivadoMutation()


    const handleToggleActivar = () => {
        toggleActivado(id)
    }

    useEffect(() => {
        if (isErrortoggleActivado) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDatatoggleActivado.data.message
            }))
        }
    }, [isErrortoggleActivado])

    useEffect(() => {
        if (isSuccesstoggleActivado) {
            
            getRegistro(id)
            
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Registro Actualizado!"
            }))
        }
    }, [isSuccesstoggleActivado])

    if( isLoadingtoggleActivado ) return(<Loading />)

    return (
        <>
            <p>Para Activar/Desactivar un usuario deberá hacerse desde <b>PAGOTIC</b> y automáticamente se aplicará aquí.</p>
        </>
    )

}