import { React, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Moment from 'react-moment'

import { useGetByIdAbonadoMutation, useRemoveAbonadoMutation } from '../../../store/api/endpoints/abonadosApiSlice'

import { openFeedback } from '../../../store/slices/feedback'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'

import ToggleActivado from './ToggleActivado'

import { Breadcrumbs, Button, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import SearchIcon from '@mui/icons-material/Search'
import GroupIcon from '@mui/icons-material/Group'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteIcon from '@mui/icons-material/Delete'


export default function AbonadoEliminar() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetByIdAbonadoMutation()

    const [
        removeAbonado,
        {
            data: responseRemoveRegistro,
            isUninitialized: isUninitializedRemoveRegistro,
            isLoading: isLoadingRemoveRegistro,
            isSuccess: isSuccessRemoveRegistro,
            isError: isErrorRemoveRegistro,
            error: errorDataRemoveRegistro,
        }
    ] = useRemoveAbonadoMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorRemoveRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataRemoveRegistro.data.message
            }))
        }
    }, [isErrorRemoveRegistro])

    useEffect(() => {
        if (isSuccessRemoveRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se eliminó el registro!"
            }))
            navigate("/abonados/activos")
        }
    }, [isSuccessRemoveRegistro])

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingRemoveRegistro || isSuccessRemoveRegistro) return (<Loading />)

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Abonados</Typography>
                        <Typography color="text.primary">{responseGetRegistro.nombre}</Typography>
                        <Typography color="text.primary">Eliminar</Typography>
                    </Breadcrumbs>
                }
                icon={<GroupIcon />}
            />
            <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
                    <h3>¿Eliminar Abonado?</h3>
                    <p>Se eliminará el abonado y no podrá recuperarse.</p>
                </Grid>
                <Grid size={{ xs: 12, md: 2 }}></Grid>
                <Grid size={{ xs: 12, md: 4 }} sx={{ textAlign: 'left' }}>
                    <p><b>DNI:</b> {responseGetRegistro.dni}</p>
                    <p><b>Nombre:</b> {responseGetRegistro.nombre}</p>
                    <p><b>Email:</b> {responseGetRegistro.email}</p>
                    <p><b>Teléfono:</b> {responseGetRegistro.telefono}</p>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} sx={{ textAlign: 'left' }}>
                    <p><b>CUIT:</b> {responseGetRegistro.cuit}</p>
                    <p><b>Empresa:</b> {responseGetRegistro.empresa}</p>
                    <p><b>Cond. AFIP:</b> {responseGetRegistro.condicion_afip}</p>
                    <p><b>Comerciante:</b> {responseGetRegistro.comerciante}</p>
                </Grid>
                <Grid size={{ xs: 12, md: 2 }}></Grid>
                <Grid size={{ xs: 12, md: 2 }}></Grid>
                <Grid size={{ xs: 12, md: 6 }} sx={{ textAlign: 'left' }}>
                    <p><b>¿Cómo Llegaste?:</b> {responseGetRegistro.como_llegaste}</p>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Divider />
                </Grid>
                <Grid size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
                    <Button type="button" onClick={() => removeAbonado(urlParams.id)} variant="contained" color="error" startIcon={<DeleteIcon />} >
                        Eliminar
                    </Button>
                </Grid>
            </Grid>
        </>
    )

}